import { requests } from "@/utils/requests";

export const getCompany = async () => {
  const data = await requests({
    url: `/api/service/cases/company`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getContract = async (customer_company) => {
  const data = await requests({
    url: `/api/service/cases/contract/${customer_company}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getCase = async (case_uuid = "") => {
  const data = await requests({
    url: `/api/service/cases/${case_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newCase = async (case_param) => {
  const data = await requests({
    url: "/api/service/cases",
    method: "POST",
    data: case_param,
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getOpendCase = async (contract_uuid) => {
  const data = await requests({
    url: `/api/service/cases/${contract_uuid}/opened`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getAllCase = async (contract_uuid) => {
  const data = await requests({
    url: `/api/service/cases/${contract_uuid}/all`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getClosedCase = async (contract_uuid) => {
  const data = await requests({
    url: `/api/service/cases/${contract_uuid}/closed`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getProductVersionByContract = async (
  contract_uuid,
  product_uuid
) => {
  const data = await requests({
    url: `/api/service/cases/product/versions/${contract_uuid}/${product_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const reopenCase = async (case_uuid, case_reopen_reason) => {
  const data = await requests({
    url: `/api/service/cases/reopen`,
    method: "POST",
    data: { case_uuid, case_reopen_reason },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putCaseEscalation = async (
  case_uuid,
  case_is_escalation,
  case_escalation_id
) => {
  const data = await requests({
    url: `/api/service/cases/escalation`,
    method: "PUT",
    data: { case_uuid, case_is_escalation, case_escalation_id },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putCasePriority = async (case_uuid, case_priority) => {
  const data = await requests({
    url: `/api/service/cases/changepriority`,
    method: "PUT",
    data: { case_uuid, case_priority },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putCaseAdminMemo = async (case_uuid, case_adminmemo) => {
  const data = await requests({
    url: `/api/service/cases/adminmemo`,
    method: "PUT",
    data: { case_uuid, case_adminmemo },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getCompany,
  getCase,
  newCase,
  getOpendCase,
  getAllCase,
  getClosedCase,
  getProductVersionByContract,
};
