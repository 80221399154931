<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" :class="{ 'd-none': isCollapse }">
        <v-fade-transition leave-absolute>
          <v-card elevation="4" tile class="mb-2">
            <v-card-title class="pa-0">
              <v-toolbar color="#083272" dark>
                <v-toolbar-title>
                  <h5 class="font-weight-bold suptitle">1. Pick a Company</h5>
                </v-toolbar-title>
                <template v-slot:extension>
                  <v-autocomplete
                    v-model="selectedCompany"
                    solo
                    light
                    dense
                    :items="companyList"
                    class="px-1"
                  ></v-autocomplete>
                </template>
              </v-toolbar>
            </v-card-title>
          </v-card>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-card elevation="4" class="my-2">
            <v-card-title class="pa-0">
              <v-toolbar color="#083272" dark>
                <v-toolbar-title>
                  <h5 class="font-weight-bold suptitle">2. Pick a Project</h5>
                </v-toolbar-title>
                <template v-slot:extension>
                  <v-autocomplete
                    v-model="selectedProject"
                    solo
                    light
                    dense
                    :items="projectList"
                    class="px-1"
                  ></v-autocomplete>
                </template>
              </v-toolbar>
            </v-card-title>
          </v-card>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-card elevation="4" tile class="my-2">
            <v-card-text class="pt-3" v-if="selectedProject != null">
              <v-container>
                <v-row class="pb-2">
                  <v-col cols="12"><h2>Project Info</h2></v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-5">
                  <!-- <v-col cols="12" class="py-0">
                    <span class="subtitle-1 font-weight-bold">
                      Project UUID:
                    </span>
                    <span class="subtitle-1">
                      {{ selectedProject.contract_uuid }}
                    </span>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <span class="subtitle-1 font-weight-bold">
                      Project Status:
                    </span>
                    <v-chip :color="projectStatusColor" small>
                      {{ projectStatus }}
                    </v-chip>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <span class="subtitle-1 font-weight-bold">
                      Success Offerings:
                    </span>
                    <span class="subtitle-1">
                      {{
                        selectedProject.TB_PRST_SUCCESS_OFFERING.offering_name
                      }}
                    </span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <span class="subtitle-1 font-weight-bold">
                      Entitlement End Date:
                    </span>
                    <span class="subtitle-1">{{
                      selectedProject.contract_end_date.split("T")[0]
                    }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-fade-transition>
      </v-col>
      <v-fade-transition leave-absolute>
        <v-col
          cols="12"
          :md="isCollapse ? 12 : 9"
          v-if="selectedCompany != null && selectedProject != null"
        >
          <Cases
            :company="selectedCompany"
            :project="selectedProject"
            :is_active="is_active"
            :isCollapse="isCollapse"
            :onCollapse="collapseToggle"
          />
        </v-col>
      </v-fade-transition>
    </v-row>
  </v-container>
</template>

<script>
import { getCompany, getContract } from "@/api/Case";
import Cases from "@/components/Views/Home/Cases";
import { getUnreadPosts } from "@/api/Post";
import * as moment from "moment";
export default {
  name: "Home",
  components: { Cases },
  beforeMount() {
    this.checkUnreadPost();
    this.getCompany();
  },
  computed: {
    storedCompany() {
      return this.$store.getters["mainDash/selectedCompany"];
    },
    storedProject() {
      return this.$store.getters["mainDash/selectedContract"];
    },
    projectStatus() {
      const enddate = moment(this.selectedProject.contract_end_date);
      const current = moment();
      console.log();
      if (enddate - current < 0) {
        return "Expired";
      }
      if (this.selectedProject.contract_is_active) {
        if (this.selectedProject.contract_is_in_grace_period) {
          return "In Grace Period";
        }
        return "Active";
      }
      return "Expired";
    },
    projectStatusColor() {
      const enddate = moment(this.selectedProject.contract_end_date);
      const current = moment();
      console.log();
      if (enddate - current < 0) {
        return "error";
      }
      if (this.selectedProject.contract_is_active) {
        if (this.selectedProject.contract_is_in_grace_period) {
          return "warning";
        }
        return "success";
      }
      return "error";
    },
    is_active() {
      const enddate = moment(this.selectedProject.contract_end_date);
      const current = moment();
      console.log();
      if (enddate - current < 0) {
        return false;
      }
      if (this.selectedProject.contract_is_active) {
        return true;
      }
      return false;
    },
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
  },
  methods: {
    async getCompany() {
      const data = await getCompany();
      if (data.success) {
        const list = data.data.map((e) => {
          const expired = e.actived_companies ? "" : "[EXPIRED] ";
          let open_cases = e.open_cases;
          const opencases = open_cases ? ` (${open_cases})` : "";
          return {
            is_active: e.actived_companies,
            text: expired + e.company_name + opencases,
            value: e,
          };
        });
        list.sort((a, b) => {
          if (a.is_active && !b.is_active) return -1;
        });
        this.companyList = list;
      }

      this.selectedCompany = this.storedCompany || this.companyList[0].value;
      await this.changeCompany();
    },
    async changeCompany() {
      this.$store.commit("mainDash/updateSelection", {
        selectedCompany: null,
        selectedContract: null,
      });
      this.selectedProject = null;
      this.projectList = [];
      console.log(this.selectedCompany, this.companyList[0].value);
      const data = await getContract(this.selectedCompany.company_uuid);
      if (data.success) {
        const list = data.data.map((e) => {
          let is_active =
            e.contract_is_active && moment(e.contract_end_date) - moment() >= 0;
          let open_cases = e.TB_DATA_CASE_LISTs[0]
            ? e.TB_DATA_CASE_LISTs[0].open_cases
            : 0;
          const expired = is_active ? "" : "[EXPIRED] ";
          const opencases = open_cases ? ` (${open_cases})` : "";
          return {
            is_active: is_active,
            text: expired + e.contract_name + opencases,
            value: e,
          };
        });
        list.sort((a, b) => {
          if (a.is_active && !b.is_active) return -1;
        });
        this.projectList = list;
      }
      if (this.projectList.length > 0)
        this.selectedProject = this.storedProject || this.projectList[0].value;
    },
    collapseToggle() {
      this.isCollapse = !this.isCollapse;
    },
    async checkUnreadPost() {
      // if (this.isAdmin) return;
      const data = await getUnreadPosts();
      if (data.success) {
        // console.log(data);
      }
    },
  },
  watch: {
    selectedCompany() {
      this.changeCompany();
    },
  },
  data() {
    return {
      companyList: [],
      projectList: [],
      selectedCompany: null,
      selectedProject: null,
      isCollapse: false,

      unReadNotice: true,
    };
  },
};
</script>
